<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card v-if="user">
      <v-card-title>
        <v-row justify="start" align="center" no-gutters>
          <div><v-icon>fas fa-lock</v-icon></div>
          <div class="headline ml-1">{{$t('common.accounts.userDetailsDialog.title')}}</div>
        </v-row>

      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          {{user.person.firstname}} {{user.person.name}}
        </v-row>
      </v-card-text>
    </v-card>
    <Skeleton
        v-else
        type="list-item-avatar-two-line"
        :occurrence="1" />
  </v-dialog>
</template>

<script>
import dialog from "@/mixins/dialog"

export default {
  name: "AccountUserDetailsDialog",

  mixins: [dialog],

  components: {
    Skeleton: () => import("@/components/Common/Cards/Skeleton")
  },

  props: {
    user: {type: Object, default:  () => null}
  },
}
</script>

<style scoped>

</style>